<template>
  <v-container
    fluid
    class="logs-tab"
  >
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <div>
              <span>
                {{ `${$t('statistics.percentageOfFailedLoginAttempts')}:`}}
              </span>
              <span>
                <b>{{ percentageOfFailedLoginAttempts }}</b>
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <data-table
              ref="dataTable"
              :headers="headers"
              endpoint="/Statistic/GetLogins"
              :additionalParams="{ objectId: $route.params.id }"
              serverPagination
            >
              <template v-slot:item.dateTime={item}>
                <span>{{ item.dateTime | dateFormat('DD.MM.YYYY HH:mm') }}</span>
              </template>
              <template v-slot:item.successfulLogin={item}>
                <v-chip v-if="item.successfulLogin">{{ $t('statistics.login') }}</v-chip>
                <v-chip v-else>{{ $t('statistics.noLogin') }}</v-chip>
              </template>
              <template v-slot:no-data>
                {{ $t("rating.noData") }}
              </template>
            </data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Service from '@/services'
import DataTable from '@/components/DataTable'

export default {
  name: 'LogsTab',
  components: {
    DataTable
  },
  data () {
    return {
      percentageOfFailedLoginAttempts: null,
      loading: true,
      headers: [
        {
          text: this.$t('statistics.date'),
          value: 'dateTime'
        },
        {
          text: this.$t('statistics.user'),
          value: 'name'
        },
        {
          text: this.$t('statistics.status'),
          value: 'successfulLogin'
        }
      ]
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const { data } = await Service.get('Statistic/GetPercentageOfFailedLoginAttempts')
        this.percentageOfFailedLoginAttempts = data
      } catch (e) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
