<template>
  <v-container fluid>
    <v-row
      v-if="!loading"
      class="px-4"
    >
      <v-col cols="12" md="6" v-for="(item, index) in data" :key="index">
        <v-card>
          <v-card-title class="text-h5">
            {{ getUserRole(item.type) }}
          </v-card-title>
          <v-card-text class="h-100 d-flex align-end">
            <v-row no-gutters>
              <v-col cols="12">
                <p>{{ $t('statistics.active') }}: {{ item.activeCount }}</p>
                <p>{{ $t('statistics.close') }}: {{ item.closedCount }}</p>
                <p>{{ $t('statistics.averageFilingTime') }}: {{ item.averageCloseTime }}</p>
                <p>{{ $t('statistics.activeInCustomerService') }}: {{ item.activeInCustomerService }}</p>
                <p>{{ $t('statistics.activeInDebtRecovery') }}: {{ item.activeInDebtRecovery }}</p>
                <p>{{ $t('statistics.activeInLegalDepartment') }}: {{ item.activeInLegalDepartment }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Service from '@/services'
import { getEnumDescription } from '@/utils/helpers'

export default {
  name: 'IssuesTab',
  data () {
    return {
      loading: true,
      data: null
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      try {
        const { data: categoriesIssue } = await Service.get('Statistic/GetIssueStats')
        this.data = categoriesIssue
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    getUserRole (type) {
      return getEnumDescription(this.$store.getters.enums.issueTypes, type)
    }
  }
}
</script>

<style scoped>

</style>
